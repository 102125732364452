<template>
  <div style="border: 1px solid #e4e4e4;">
    <el-menu
      :uniqueOpened="true"
      class="el-menu-vertical"
      :default-openeds="openedItem"
      @select="handleSelect"
      text-color="#323232"
      >      
      <!-- @open="handleOpen" -->
      <!-- @close="handleClose" -->
      <el-submenu 
        v-for="item,index in menuList" :key="index"
        :index='`${index}`'
      >
        <template #title>
          <span>{{ item.treeName }}</span>
        </template>
        <el-menu-item 
          v-for="title,idx in item.treeCont" :key="idx"
        :index="`${index}` + '/' + `${idx}`">
          {{ title.title }}
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    menuList: {
      type: Array,
      default: () => {
        return []
      }
    },
    url: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data()  {
    return  {
      openedItem: ["0"]
    }
  },
  methods: {
    // handleClose(key) {
    //   alert(key)
    // },
    // handleOpen(key) {
    //   this.$router.push('/atlas/' + key.toString());     
    // },
    handleSelect(key) {
      this.$router.push(this.url + key.toString());
    }
  }
}
</script>

<style scoped>
li.el-submenu,
li.el-submenu > div:nth-child(1) {
  text-align: center !important;
  border-bottom: 1px solid #e4e4e4 !important;  
}
li.el-submenu > ul > li {
  padding-left: 20px !important;
  text-align: initial;
  border-top: 1px solid #e4e4e4 !important;
}
li.el-submenu > ul > li:hover {
  background: #199be9 !important;
  color: #fff !important;
}
li.is-active {
  background: #eff8fc;
}
</style>